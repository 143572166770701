<template>
  <div>
    <div class="title flexB">
      <h1>{{ $t("175") }}</h1>
    </div>
    <div class="contents userMgt">
      <div class="box one filter">
        <div class="flex">
          <p class="bold">{{ $t("824") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("825") }}</option>
            <option value="name">{{ $t("826") }}</option>
            <option value="loginId">{{ $t("827") }}</option>
            <option value="departmentName">{{ $t("828") }}</option>
            <option value="mobileNumber">{{ $t("829") }}</option>
          </select>
          <input
            type="text"
            v-model="keyword"
            @keydown.enter="getGuestList('search')"
          />
        </div>
        <div class="flex">
          <p class="bold">{{ $t("830") }}</p>
          <Datepicker
            v-model="startYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
            v-model="endYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="flex">
          <p class="bold">{{ $t("842") }}</p>
          <label>
            <input
              type="radio"
              name="service"
              checked
              v-model="connectionDevice"
              value=""
            />{{ $t("825") }}
          </label>
          <label>
            <input
              type="radio"
              name="service"
              v-model="connectionDevice"
              value="ControllerApp"
            />{{ $t("833") }}
          </label>
          <label>
            <input
              type="radio"
              name="service"
              v-model="connectionDevice"
              value="ViewApp"
            />{{ $t("834") }}
          </label>
          <label>
            <input
              type="radio"
              name="service"
              v-model="connectionDevice"
              value="Web"
            />{{ $t("835") }}
          </label>
        </div>
        <div class="buttonWrap">
          <button class="point large" @click="getGuestList('search')">
            {{ $t("840") }}
          </button>
          <button class="large margin6" @click="$router.go()">
            {{ $t("841") }}
          </button>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          총
          <span class="blue">{{ total }}</span
          >건
        </h2>
        <table>
          <tr>
            <th>NO</th>
            <th>{{ $t("842") }}</th>
            <th>{{ $t("843") }}</th>
            <th>{{ $t("844") }}</th>
            <th>{{ $t("845") }}</th>
            <th>{{ $t("846") }}</th>
            <th>{{ $t("847") }}</th>
            <th>{{ $t("848") }}</th>
            <th>{{ $t("849") }}</th>
          </tr>
          <tr v-for="(data, i) in guestList" :key="i">
            <td>{{ total - ((currentPage - 1) * size + i) }}</td>
            <td>
              {{
                data.device == "ViewApp"
                  ? $t("834")
                  : data.device == "Web"
                  ? $t("835")
                  : data.device == "ControllerApp"
                  ? $t("833")
                  : "-"
              }}
            </td>
            <td>
              {{ data.workerType == "Guest" ? $t("839") : data.workerType }}
            </td>
            <td>{{ data.name }}</td>
            <td>{{ data.loginId }}</td>
            <td>{{ data.departmentName }}</td>
            <td>{{ getMask(data.mobileNumber) }}</td>
            <td>{{ moment(data.loginDate).format("YYYY.MM.DD HH:mm") }}</td>
            <td>
              {{
                data.lassRequestDate
                  ? moment(data.lassRequestDate).format("YYYY.MM.DD HH:mm")
                  : "-"
              }}
            </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
            small
            layout="prev, pager, next"
            :total="total"
            :page-size="size"
            @current-change="handleCurrentChange"
            :current-page="currentPage + 1"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchGuestList } from "@/api/index";
import { format } from "@/mixins/format";

export default {
  mixins: [format],
  components: { Datepicker },
  name: "Home",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: "",
      endYmd: "",
      currentPage: 1,
      total: 0,
      size: 10,
      keywordType: "all",
      keyword: "",
      connectionDevice: "",
      guestList: [],
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 8, sub: 3 });
  },
  mounted() {
    this.setDate();
    this.getGuestList();
  },
  methods: {
    setDate() {
      this.endYmd = moment().format("YYYY-MM-DD");
      let twoWeek = moment().get("date") - 14;
      this.startYmd = moment().set("date", twoWeek).format("YYYY-MM-DD");
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },

    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getGuestList();
    },
    getGuestList(isSearch) {
      if (isSearch) {
        if (this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
        this.currentPage = 1;
      }
      let params = {
        pageNumber: this.currentPage - 1,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
        ConnectionDevice: this.connectionDevice,
        dateRange: {
          from: moment(this.startYmd).format("YYYY-MM-DD"),
          to: moment(this.endYmd).format("YYYY-MM-DD"),
        },
      };
      fetchGuestList(params).then((res) => {
        this.total = res.data.data.total;
        this.guestList = res.data.data.content;
      });
    },
  },
};
</script>
